export const SEO_STRAPI_POPULATION = {
  populate: {
    metaImage: {
      populate: "*",
    },
    metaSocial: {
      populate: "*",
    },
  },
};
export const monthNamesShort = [
  "янв",
  "фев",
  "мар",
  "апр",
  "май",
  "июн",
  "июл",
  "авг",
  "сен",
  "окт",
  "ноя",
  "дек",
];

export const monthNames = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

export const weekDayNames = [
  'Воскресенье',
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота'
]
export const COOKIE_KEYS = {
  authToken: "auth._token.local",
  i18n: "i18n.locale",
};
